<script>
import accountMixin from "@/mixins/accounts.js";
import View from "@/views/View";
import LoadSections from "@/mixins/load-sections";

export default {
  name: "Card",
  metaInfo: { title: "Verify Card" },
  extends: View,
  data: () => ({
    components: [],
  }),
  mixins: [accountMixin, LoadSections(["check-card", "info"])],
  props: {
    id: {
      type: String,
      default: "about",
    },
  },
};
</script>
